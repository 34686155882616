export default {
  4: [
    [1, 2],
    [2, 3],
  ],
  5: [
    [1, 3],
    [2, 3],
  ],
  6: [
    [1, 2],
    [2, 4],
    [4, 5],
  ],
  7: [
    [1, 3],
    [2, 3],
    [4, 5],
    [4, 6],
  ],
  8: [
    [2, 4],
    [4, 6],
  ],
}
